// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.bundle';
// import '../assets/css/style.css';
// import '../assets/js/main';
import {
  Twitter,
  Facebook,
  Instagram,
  ArrowUpShort,
  ChevronRight,
  Youtube,
  Linkedin,
} from "react-bootstrap-icons";
import { useEffect } from "react";

function Footer() {
  useEffect(() => {
    document.getElementById("copyright").innerHTML = 'Copyright &copy; ' + new Date().getFullYear() + '<strong><span> 2020 Group LLC</span></strong>. All Rights Reserved'
    
  }, []);
  return (
    <>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="footer-info">
                  {/* <!--h3>2020 Group LLC</h3--> */}
                  <a href="index.html">
                    <img
                      src="{% static 'assets/img/2020group_logo.png' %}"
                      alt=""
                      className="img-fluid"
                      width="200"
                    />
                  </a>
                  <p>
                    3475 Oak Valley Rd Suite 2330 <br />
                    Atlanta GA 30326, USA
                    <br />
                    <br />
                    <strong>Phone:</strong>{" "}
                    <a href="tel:+14043840917">+1 (404) 384-0917</a>
                    <br />
                    <strong>Info:</strong>{" "}
                    <a href="mailto:info@2020groupllc.com">
                      info@2020groupllc.com
                    </a>
                    <br />
                    <strong>Consulting:</strong>{" "}
                    <a href="mailto:info@2020groupllc.com">
                      consults@2020groupllc.com
                    </a>
                    <br />
                    <strong>Communication:</strong>{" "}
                    <a href="mailto:info@2020groupllc.com">
                      comm@2020groupllc.com
                    </a>
                    <br />
                    <strong>Real Estate Investment:</strong>{" "}
                    <a href="mailto:info@2020groupllc.com">
                      reit@2020groupllc.com
                    </a>
                    <br />
                    <strong>International:</strong>{" "}
                    <a href="mailto:info@2020groupllc.com">
                      intl@2020groupllc.com
                    </a>
                    <br />
                  </p>
                  <div className="social-links mt-3">
                    <a href="https://www.twitter.com/" className="twitter">
                      <i className="bx bxl-twitter">
                        <Twitter />
                      </i>
                    </a>
                    <a href="https://www.facebook.com/" className="facebook">
                      <i className="bx bxl-facebook">
                        <Facebook />
                      </i>
                    </a>
                    <a href="https://www.instagram.com/" className="instagram">
                      <i className="bx bxl-instagram"></i>
                      <Instagram />
                    </a>
                    <a href="https://www.youtube.com" className="youtube">
                      <i className="bx bxl-youtube">
                        <Youtube />
                      </i>
                    </a>
                    <a href="https://www.linkedin.com/in/" className="linkedin">
                      <i className="bx bxl-linkedin">
                        <Linkedin />
                      </i>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"><ChevronRight /></i>{" "}
                    <a href="{% url 'index' %}#hero">Home</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"><ChevronRight /></i>{" "}
                    <a href="{% url 'index' %}#about">What We Do</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"><ChevronRight /></i>{" "}
                    <a href="{% url 'index' %}#why-us">Why Us</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"><ChevronRight /></i>{" "}
                    <a href="{% url 'index' %}#overview">Overview</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"><ChevronRight /></i>{" "}
                    <a href="{% url 'index' %}#services">Our Services</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"><ChevronRight /></i>{" "}
                    <a href="{% url 'index' %}#contact">Contact</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"><ChevronRight /></i>{" "}
                    <a href="{% url 'services' 'International' %}">
                      International
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"><ChevronRight /></i>{" "}
                    <a href="{% url 'services' 'Consults' %}">Consults</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"><ChevronRight /></i>{" "}
                    <a href="{% url 'services' 'Investment' %}">Investment</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"><ChevronRight /></i>{" "}
                    <a href="{% url 'services' 'Communications' %}">
                      Communications
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-newsletter">
                <h4>Our Newsletter</h4>
                <p>Subscribe to our newsletter</p>
                <form action="" method="post">
                  <input type="email" name="email" />
                  <input type="submit" value="Subscribe" />
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="copyright" id="copyright">
            Copyright &copy;{" "}
            document.write(new Date().getFullYear())
            <strong>
              <span> 2020 Group LLC</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            {/* <!-- All the links in the footer should remain intact. -->
        <!-- You can delete the links only if you purchased the pro version. -->
        <!-- Licensing information: https://bootstrapmade.com/license/ -->
        <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/restaurantly-restaurant-template/ --> */}
            Powered by{" "}
            <a href="https://www.gnotable.ng/">Gnotable Technologies</a>
            {/* <!--        Template by <a href="https://bootstrapmade.com/">BootstrapMade</a>--> */}
          </div>
        </div>
      </footer>
      {/* <div id="preloader"></div> */}
      <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><ArrowUpShort /></a>
    </>
  );
}

export default Footer;
