import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/js/main'

import Hero from './components/Hero'
import Services from './components/Services';
import About from './components/About';
import Overview from './components/Overview';
import WhyUs from './components/Whyus';
import Header from './components/Header';
import Topbar from './components/Topbar';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { Phone, ClockFill, List } from 'react-bootstrap-icons'
import {
  Route,
  Routes,
  BrowserRouter,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider
} from "react-router-dom";
import HomePage from './pages/home-page';
import InternationalPage from './pages/international-page';
import ConsultsPage from './pages/consults-page';
import CommunicationsPage from './pages/communications-page';
import InvestmentPage from './pages/investment-page';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route index element={<HomePage />} />
      <Route path="international" element={<InternationalPage page="International" />} />
      <Route path="consults" element={<ConsultsPage page="Consults" />} />
      <Route path="communication" element={<CommunicationsPage page="Communications" />} />
      <Route path="investment" element={<InvestmentPage page="Investment" />} />
      
    </>
  )
);


function App() {
    
    return (

<RouterProvider router={router} />
)

}


export default App; 
