// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.bundle';
// import '../assets/css/style.css';
// import '../assets/js/main';


function WhyUs() {
    return (
        <>
            <section id="why-us" className="why-us">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Why Us</h2>
          <p>Why Choose Us</p>
        </div>

        <div className="row">

          <div className="col-lg-4">
            <div className="box" data-aos="zoom-in" data-aos-delay="100">
              <span>01</span>
              <h4>Experience</h4>
              <p>Our team have vast experience in our areas of expertise</p>
            </div>
          </div>

          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="box" data-aos="zoom-in" data-aos-delay="200">
              <span>02</span>
              <h4>Professionalism</h4>
              <p>We are professional in our service delivery</p>
            </div>
          </div>

          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="box" data-aos="zoom-in" data-aos-delay="300">
              <span>03</span>
              <h4>Creativity</h4>
              <p>We find creative and modern ways of solving problems</p>
            </div>
          </div>

        </div>

      </div>
    </section>
        </>
    )

}

export default WhyUs;