import "bootstrap/dist/css/bootstrap.css";
import "../assets/css/style.css";
import "../assets/js/main";

import Hero from "../components/Hero";
import Services from "../components/Services";
import About from "../components/About";
import Overview from "../components/Overview";
import WhyUs from "../components/Whyus";
import Header from "../components/Header";
import Topbar from "../components/Topbar";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import { useEffect } from "react";

function HomePage() {
  useEffect(() => {
    const preloader = document.querySelector("#preloader");
    if (preloader) {
      window.addEventListener("load", () => {
        preloader.remove();
      });
    }
  }, []);
  return (
    <>
      <Topbar />
      <Header />

      <main id="main">
        <Hero />
        <About />
        <WhyUs />
        <Overview />
        <Services />
        <Contact />
      </main>
      <Footer />
    </>
  );
}

export default HomePage;
