// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.bundle';
// import '../assets/css/style.css';
// import '../assets/js/main';
import { useEffect } from "react";
import { Phone, Clock } from "react-bootstrap-icons";

function Topbar() {
    useEffect(() => {
    const topbar = document.querySelector('#topbar');
    
    if (topbar) {
      const headerScrolled = () => {
        topbar.classList.toggle('topbar-scrolled', window.scrollY > 100);
      };
    
      window.addEventListener('load', headerScrolled);
      document.addEventListener('scroll', headerScrolled);
    }
}, []);
    return (
        <>
            <div id="topbar" className="d-flex align-items-center fixed-top">
    <div className="container d-flex justify-content-center justify-content-md-between">

      <div className="contact-info d-flex align-items-center">
        <i className="bi bi-phone d-flex align-items-center"><Phone /><span><a href="tel:+14043840917" className="text-white"> +1 (404) 384-0917</a></span></i>
        <i className="bi bi-clock d-flex align-items-center ms-4"><Clock/><span> Mon-Sat: 11AM - 5PM</span></i>
      </div>

      
    </div>
  </div>
        </>
    )

}

export default Topbar;