import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "../assets/css/style.css";
import "../assets/js/main";
import Breadcrumbs from "../components/Breadcrumbs";
import { Investment } from "../components/ServiceDetails";
import Header from "../components/Header";
import Topbar from "../components/Topbar";
import Footer from "../components/Footer";

function InvestmentPage(props) {
  return (
    <>
      <Topbar />
      <Header />

      <main id="main">
        <Breadcrumbs page="Investment" />
        <Investment />
      </main>
      <Footer />
    </>
  );
}

export default InvestmentPage;
