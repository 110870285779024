// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.bundle';
// import '../assets/css/style.css';
// import '../assets/js/main';
import image from '../assets/img/about3.webp';

function About() {
    return (
        <>
            <section id="about" className="about">
      <div className="container" data-aos="fade-up">

        <div className="row">
          <div className="col-lg-6 order-1 order-lg-2" data-aos="zoom-in" data-aos-delay="100">
            <div className="about-img">
              <img src={image} alt=""/>
            </div>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
            <h3 className="mb-3">What we do</h3>
            <p className="fst-italic mb-2">
              We offer services ranging from consultation to investment and strategy. <br/>Our services are top-notch - 20/20
            </p>
            <ul>
              <li><i className="bi bi-check-circle"></i> 20/20 International (Energy, Oil & Gas, Enterprise Development, Trade)</li>
              <li><i className="bi bi-check-circle"></i> 20/20 Consults</li>
              <li><i className="bi bi-check-circle"></i> 20/20 Investment Advisory</li>
              <li><i className="bi bi-check-circle"></i> 20/20 Communications</li>
            </ul>
            <p>
              20/20 Group, LLC the world’s leading global provider of Advisory, Consulting & Integrated Training solutions is
              registered in the State of Georgia with virtual offices in major countries in the world. We help our clients
              maximize the return on investment through business strategy and technology resourcefulness to bridge the gap
              between our partners, clients and end-users with an array of services
            </p>
          </div>
        </div>

      </div>
    </section>
        </>
    )

}

export default About;