// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.bundle';
// import '../assets/css/style.css';
// import '../assets/js/main';
import { GeoAlt, Phone, Envelope, Clock } from "react-bootstrap-icons";


function Contact() {
    return (
        <>
            <section id="contact" className="contact">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Contact</h2>
          <p>Contact Us</p>
        </div>
      </div>

      {/* <div data-aos="fade-up">
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe width="100%" height="350" id="gmap_canvas" src="https://maps.google.com/maps?q=3475%20Oak%20Valley%20Rd%20Suite%202330,%20Atlanta%20GA%2030326,%20USA&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
            <style>.mapouter{position:relative;text-align:right;height:350px;width:100%;}</style>
            <style>.gmap_canvas {overflow:hidden;background:none!important;height:350px;width:100%;}</style>
          </div>
        </div>
        <!--iframe style="border:0; width: 100%; height: 350px;" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" allowfullscreen></iframe-->
      </div> */}

      <div className="container" data-aos="fade-up">

        <div className="row mt-5">

          <div className="col-lg-4">
            <div className="info">
              <div className="address">
              
                <i className="bi bi-geo-alt"><GeoAlt size={20} /></i>
                <h4>Location:</h4>
                <p>3475 Oak Valley Rd Suite 2330, Atlanta GA 30326, USA</p>
              </div>

              <div className="open-hours">
                <i className="bi bi-clock"><Clock size={20} /></i>
                <h4>Open Hours:</h4>
                <p>
                  Monday-Saturday:<br/>
                  11:00 AM - 11:00 PM
                </p>
              </div>

              <div className="email">
                <i className="bi bi-envelope"><Envelope size={20} /></i>
                
                <h4>Email:</h4>
                <p><a href="mailto:info@2020groupllc.com">info@2020groupllc.com</a></p>
              </div>

              <div className="phone">
              
                <i className="bi bi-phone"><Phone size={20} /></i>
                <h4>Call:</h4>
                <p><a href="tel:+14043840917">+1 (404) 384-0917</a></p>
              </div>

            </div>

          </div>

          <div className="col-lg-8 mt-5 mt-lg-0">

            <form action="forms/contact.php" method="post" role="form" className="php-email-form">
              <div className="row">
                <div className="col-md-6 form-group">
                  <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required/>
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required/>
                </div>
              </div>
              <div className="form-group mt-3">
                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required/>
              </div>
              <div className="form-group mt-3">
                <textarea className="form-control" name="message" rows="8" placeholder="Message" required></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div className="text-center"><button type="submit">Send Message</button></div>
            </form>

          </div>

        </div>

      </div>
    </section>
        </>
    )

}

export default Contact;