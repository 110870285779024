// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.bundle';
// import '../assets/css/style.css';
// import '../assets/js/main';
import { Link } from "react-router-dom";

function International() {
  return (
    <section className="inner-page">
      <div class="container">
        <p>
          <a href="">
            <img
              class="img-fluid float-end"
              src={require('../assets/img/oil-rig.png' )}
              alt="banner_company"
              width="300"
            />
          </a>
          We act as agents, brokers, direct mandates for sellers and buyers of
          JET FUEL, REBCO, GAS OIL – D2, AGO, LUBRICANTS, LNG and CRUDE OIL
          (Bonny Light &amp; various grades). Our clients are mostly importers
          and exporters of petroleum products and services, government agencies,
          top refineries around the world requesting assistance for the
          marketing and sales of petroleum products along with refinery related
          contract services.
        </p>
        <br />

        <p>
          Through our network of partners we can also source and purchase oil
          drilling equipment, contract drilling service arrangement,
          transportation equipment procurement, oil tanker brokerage and
          facilitate the arrangement of contract block lifting of crude oil in
          oil producing nations around the world. 20/20 Group has access to
          refinery builders and used complete refineries that can be purchased
          for a fraction of building a new one. These refineries can be
          re-located and permanently established anywhere in the world.
        </p>
        <br />

        <p>
          We assist business in promoting U.S. products and services by
          developing viable international markets and trade partners worldwide.
          With our worldwide access to many sources of financing- traditional
          and non-traditional, we work with major financial institutions and
          syndicated group of private and non-private investors who are ready
          and willing to finance projects or trades that have evidence of quick
          return on investments.
        </p>
      </div>
    </section>
  );
}

function Consults() {
  return (
    <section className="inner-page">
      <div class="container">
        <p>
          <a href="">
            <img
              class="img-fluid float-end"
              src={require('../assets/img/lecture.png')}
              alt="banner_company"
              width="300"
            />
          </a>
          We offer customized and specialized Consulting and Training services
          in a global spectrum of areas including Healthcare, Information
          Technology, Mobile Applications, Investment Banking, Communication,
          Energy, Oil &amp; Gas. Our consulting expertise in the areas of
          Business Intelligence, Systems Implementation, Systems Integration,
          and Healthcare Consulting in areas of Electronic Health Records (EHR),
          Healthcare Payer systems i.e. EPIC, CERNER, TRIZETTO FACETS, QNXT,
          NETWORX, provide and enhance the leading –edge advantage over our
          competitors. Our team of highly experienced and talented Consultants
          deliver Business Analysis, Configuration Design build, Project
          Management and Implementation, Software Testing and Training across
          various IT platforms.
        </p>
        <br />

        <p>
          We also offer IT training in Network security, JAVA, Enterprise
          resource planning solutions i.e. SAP and communication technology
          solutions. Our seasoned professionals have worked with major
          telecommunications and software development firms in the USA market
          and have been instrumental in software invention initiatives.
        </p>

        <p>
          We act as consultants in helping organizations translate business
          strategies into business results by developing the skills and
          performance of our clients. Through our extensive resources and
          experienced team of analysts, we act as facilitators, trainers and
          serve in advisory capacity in providing business solutions. All
          products, seminars and services cover all areas of business, better
          skills, better solutions and better performance.
        </p>
        <br />

        <p>
          20/20 CONSULTS guarantees that whatever your training and development
          needs are in your organization, we will assist you to meet those needs
          either directly or through our network of professional associates.
        </p>
      </div>
    </section>
  );
}

function Communication() {
  return (
    <section className="inner-page">
      <div class="container">
        <p>
          <a href="">
            <img
              class="img-fluid float-end"
              src={require('../assets/img/comms.png')}
              alt="banner_company"
              width="300"
            />
          </a>
          Undertakes artistic ventures, marketing, promotions, advertisements,
          public relations, communications, new age technologies. Our group of
          experienced professionals have over twenty years experience in working
          with major companies to produce results oriented advertising, high
          impact marketing, website to give our clientele the added exposure and
          positioning. We brand companies products and partner with you on major
          campaigns, events, and networking opportunities. We help your company
          achieve the qualified lead generation and sales results you need by
          providing a full range of targeted integrated marketing communication
          services, techniques, strategies and tactics
        </p>
        <br />
        <p>
          20/20 Communications provides the entire spectrum of media planning
          and buying services. These strategic planning and buying services
          include: Interactive and Television Commercials Broadcast and Cable
          television Network and local radio Print—magazines and newspapers
          Consulting and Creative Services
        </p>
        <p class="mb-1 mt-1">Copy writing Direct Mail</p>
        <p class="mb-1">Print and online Advertising</p>
        <p class="mb-1">Radio Advertising</p>
        <p class="mb-1">On-line Marketing</p>
        <p class="mb-1">Promotions and Events</p>
        <p>
          Public Relations 20/20 offers complete campaign development and
          management services, with exceptional capabilities in media planning,
          market analysis, media negotiation and procurement. Detailed campaign
          development and management services include:
        </p>

        <ul>
          i<li>
            {" "}
            Product or service assessment for suitability to market through
            television, radio, internet and mobile phone platforms
          </li>
          <li> Market research to properly position the product or service</li>
          <li>
            {" "}
            Campaign objective determination in accordance with client needs
          </li>
          <li>
            {" "}
            Theme development based on campaign objective(s), e.g. brand
            building or strengthening, lead generation, revenue-producing
          </li>
          <li>
            {" "}
            Scripting and production for infomercials or short-form spots
          </li>
          <li> Campaign analysis to optimize ROI</li>
          <li>
            {" "}
            Arrangement of back end services with qualified telemarketers and
            fulfillment houses
          </li>
          <li>
            {" "}
            Establishment of complementary marketing channels, such as print,
            insert-mail, and coop marketing campaigns were appropriate
          </li>
        </ul>

        <p>
          20/20’s clients have been major pharmaceuticals, multinational firms
          and new enterprises.
        </p>
      </div>
    </section>
  );
}

function Investment() {
  return (
    <section className="innerpage">
      <div class="container">
        <p>
          <a href="">
            <img
              class="img-fluid float-end"
              src={require('../assets/img/investment-bull.png')}
              alt="banner_company"
              width="300"
            />
          </a>
          Our team of professionals has knowledge of individual and regional
          markets, economies and governments in Africa and USA. We provide our
          clients with marketing analyses crucial to successful investment. We
          engage in a series of firm strategy and risk analyses including,
          accessing the needs of the firm in large and rapidly growing industry
          verticals. We support new entrants into developing markets in Africa
          whether for greenfield or brownfield projects, company acquisition, or
          start up developments. Our major goal is to bridge the gap between the
          practices of companies in different countries through long-term
          relationships with our clients by providing consistently reliable,
          actionable, and profitable advice to our clientele while working
          through the obstacles and uncertainties.
        </p>
        <br />

        <p>
          Our diligence in conducting detailed market analyses assures our
          clients have a clear understanding of the potential and limits of
          their investments in emerging African markets. In addition, we provide
          our clients with sufficient government data, financial data,
          information on key players and contacts with trade associations to
          develop appropriate strategies for gaining a competitive advantage and
          developing problem-solving techniques appropriate to industry and on a
          case-by-case and country-by-country basis.
        </p>
        <br />

        <p>
          Our investor’s subsidiary undertakes real estate funding and
          investment. We build subdivisions, multi-use facilities, hotels,
          condominiums, shopping plazas, residential and commercial properties.
          With our professional project managers, engineers, builders, and
          architects, we provide high standards and values that are
          uncompromised. Other sectors where our professionals support clients
          include Healthcare, Power, Energy, Infrastructural projects,
          Agriculture and Information Technology across key African economies
          such as Nigeria, Ghana, Senegal, South Africa, Kenya and Uganda.
        </p>
        <br />

        <p>
          Terms and conditions: This is dependent upon the specific project and
          the perceived risk of the project and the strength of the principals.
        </p>
        <p>
          <strong>Information we need for initial submission:</strong>
        </p>
        <ul>
          <li>Resumes required on the principals.</li>
          <li>Equity Contribution?</li>
          <li>Required fund Amount looking to borrow?</li>
          <li>
            Anticipated annual revenue, and the net income of the project, for a
            three-year period.
          </li>
          <li>
            Current financial statements are required from the principals or
            entity.
          </li>
          <li>Exit Strategy.</li>
        </ul>
      </div>
    </section>
  );
}

export { International, Consults, Communication, Investment };
