import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "../assets/css/style.css";
import "../assets/js/main";
import Breadcrumbs from "../components/Breadcrumbs";
import { International } from "../components/ServiceDetails";
import Header from "../components/Header";
import Topbar from "../components/Topbar";
import Footer from "../components/Footer";

function InternationalPage() {
  return (
    <>
      <Topbar />
      <Header />

      <main id="main">
        <Breadcrumbs page="International" />
        <International />
      </main>
      <Footer />
    </>
  );
}

export default InternationalPage;
