// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.bundle';
// import '../assets/css/style.css';
// import '../assets/js/main';


function Overview() {
    return (
        <>
            <section id="overview" className="specials">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Overview</h2>
          <p>Overview of our services</p>
        </div>

        <div className="row" data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-3">
            <ul className="nav nav-tabs flex-column">
              <li className="nav-item">
                <a className="nav-link active show" data-bs-toggle="tab" href="#tab-1">International</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#tab-2">Consults</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#tab-3">Investment</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#tab-4">Communications</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-9 mt-4 mt-lg-0">
            <div className="tab-content">
              <div className="tab-pane active show" id="tab-1">
                <div className="row">
                  <div className="col-lg-8 details order-2 order-lg-1">
                    <h3>Petroleum Marketing</h3>
                    <p className="fst-italic mb-3">Promoting U.S. products and services</p>
                    <p>We assist businesses in promoting U.S. products and services by developing viable international markets and trade partners worldwide.
                      With our worldwide access to many sources of financing - traditional and non-traditional, we work with major financial...</p>
                  </div>
                  <div className="col-lg-4 text-center order-1 order-lg-2">
                    <img src={ require('../assets/img/oil_barrel.png') } alt="" className="img-fluid"/>
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="tab-2">
                <div className="row">
                  <div className="col-lg-8 details order-2 order-lg-1">
                    <h3>Consultancy and Training</h3>
                    <p className="fst-italic mb-3">Customized and specialized training services</p>
                    <p>We offer customized and specialized training services in a global spectrum of areas including Investment
                    Banking, Information Technology, Communication, Energy, Oil & Gas. Our programs are structured and delivered
                    by the brightest in the field that provide...</p>
                  </div>
                  <div className="col-lg-4 text-center order-1 order-lg-2">
                    <img src={ require( '../assets/img/staff.png')} alt="" className="img-fluid"/>
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="tab-3">
                <div className="row">
                  <div className="col-lg-8 details order-2 order-lg-1">
                    <h3>Investment</h3>
                    <p className="fst-italic mb-3">Real Estate Funding and Investment</p>
                    <p>We undertake real estate funding and investment through our investment subsidiary. We build subdivisions, multi-use
                    facilities, hotels, condominiums, shopping plazas, residential and commercial properties.  With our professional
                    project managers, engineers, builders...</p>
                  </div>
                  <div className="col-lg-4 text-center order-1 order-lg-2">
                    <img src={ require( '../assets/img/investment-home.png')} alt="" className="img-fluid"/>
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="tab-4">
                <div className="row">
                  <div className="col-lg-8 details order-2 order-lg-1">
                    <h3>Communications</h3>
                    <p className="fst-italic mb-3">Communication services through new age technologies</p>
                    <p>We undertake artistic ventures, marketing, promotions, advertisements, public relations, communications,
                    new age technologies. Our group of experienced professionals have over twenty years experience working with major companies...</p>
                  </div>
                  <div className="col-lg-4 text-center order-1 order-lg-2">
                    <img src={ require( '../assets/img/network.png') } alt="" className="img-fluid"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
        </>
    )

}

export default Overview;