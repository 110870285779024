// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.bundle';
// import '../assets/css/style.css';
// import '../assets/js/main';
import { Link } from "react-router-dom";

function Breadcrumbs(props) {
  return (
    <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>{props.page}</h2>
          <ol>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Services</li>
            <li>{props.page}</li>
          </ol>
        </div>
      </div>
    </section>
  );
}

export default Breadcrumbs;
