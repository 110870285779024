import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../assets/css/style.css';
import '../assets/js/main';
import Breadcrumbs from '../components/Breadcrumbs';
import { Consults, International } from '../components/ServiceDetails';
import Header from '../components/Header';
import Topbar from '../components/Topbar';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

function ConsultsPage(props) {
  
    
    return (
  
        <>
              <Topbar />
              <Header />
              
            <main id="main">
                
                <Breadcrumbs page="Consults" />
                <Consults />

            </main>
            <Footer />
        </>
        )
  
  }

  export default ConsultsPage; 