// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.bundle';
// import '../assets/css/style.css';
// import '../assets/js/main';
import { register } from "swiper/element/bundle";
import "swiper/swiper-bundle.css";
import Swiper from "swiper";

register();

new Swiper('.testimonials-slider', {
  speed: 600,
  loop: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  slidesPerView: "auto",
  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
    clickable: true,
  },
});

function Services() {
  return (
    <section id="services" className="events">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Services</h2>
          <p>More details of our services</p>
        </div>

        <div
          className="events-slider swiper"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          {/* <div className="swiper-wrapper"> */}
          <swiper-container
            space-between={50}
            slides-per-view={1}
            pagination={true}
            navigation={true}
            loop={true}
            css-mode={true}
            speed={600}
            autoplay-delay={5000}
            autoplay-disable-on-interaction={false}
            effect="slide"
            style={{ "--swiper-navigation-color": "#cda45e", "--swiper-pagination-color": "#cda45e"}}
          >
            <swiper-slide>
              {/* <div className="swiper-slide"> */}
              <div className="row event-item">
                <div className="col-lg-6">
                  <img
                    src={require("../assets/img/about1.jpg")}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-lg-6 pt-4 pt-lg-0 content">
                  <h3>International</h3>
                  <div className="price">
                    <p>
                      <span></span>
                    </p>
                  </div>
                  <p className="fst-italic mb-2">
                    We act as agents, brokers, direct mandates for sellers and
                    buyers of JET FUEL, REBCO, GAS OIL – D2, AGO, LUBRICANTS,
                    LNG and CRUDE OIL (Bonny Light & various grades).
                  </p>
                  <p>
                    Our company’s clients are mostly importers and exporters of
                    petroleum products and services, government agencies, top
                    refineries requesting assistance for the marketing and sales
                    of petroleum products along with refinery related contract
                    services.
                  </p>
                </div>
              </div>
              {/* </div><!-- End testimonial item --> */}
            </swiper-slide>
            <swiper-slide>
              {/* <div className="swiper-slide"> */}
              <div className="row event-item">
                <div className="col-lg-6">
                  <img
                    src={require("../assets/img/consults.jpg")}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-lg-6 pt-4 pt-lg-0 content">
                  <h3>Consults</h3>
                  <div className="price">
                    <p>
                      <span></span>
                    </p>
                  </div>
                  <p className="fst-italic mb-2">
                    We offer customized and specialized Consulting and Training
                    services in a spectrum of areas including Healthcare,
                    Information Technology, Mobile Applications, Investment
                    Banking, Communication, Energy, oil & Gas.
                  </p>
                  <br />
                  <p>
                    We have vast experience and expertise in the areas of
                    Business Intelligence, Systems Implementation, Systems
                    Integration. In healthcare Consulting our expertise are in
                    the areas of Electronic Health Records (EHR), Healthcare
                    Payer systems i.e. EPIC, CERNER, TRIZETTO FACETS, QNXT,
                    NETWORX. Our expertise provide great advantage over our
                    competitors.{" "}
                  </p>
                  <br />
                  <p>
                    Our team of highly experienced and talented Consultants
                    deliver Business Analysis, Configuration Design build,
                    Project Management and Implementation, Software Testing and
                    Training across various IT platforms
                  </p>
                </div>
              </div>
              {/* </div><!-- End testimonial item --> */}
            </swiper-slide>
            <swiper-slide>
              {/* <div className="swiper-slide"> */}
              <div className="row event-item">
                <div className="col-lg-6">
                  <img
                    src={require("../assets/img/reit.jpg")}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-lg-6 pt-4 pt-lg-0 content">
                  <h3>Investment Advisory</h3>
                  <div className="price">
                    <p>
                      <span></span>
                    </p>
                  </div>
                  <p className="fst-italic mb-2">
                    Our team of professionals have knowledge of individual and
                    regional markets, economies and governments in Africa and
                    USA.
                  </p>
                  <p>
                    We provide our clients with marketing analyses crucial to
                    successful investment. We engage in a series of firm
                    strategy and risk analyses including, accessing the needs of
                    the firm in large and rapidly growing industry verticals.
                  </p>
                  <br />
                  <p>
                    {" "}
                    We support new entrants into developing markets in Africa
                    whether for greenfield or brownfield projects, company
                    acquisition, or startup developments. Our major goal is to
                    bridge the gap between the practices of companies in
                    different countries through long-term relationships with our
                    clients by providing consistently reliable, actionable, and
                    profitable advice to our clientele while working through the
                    obstacles and uncertainties.
                  </p>
                </div>
              </div>
              {/* </div><!-- End testimonial item --> */}
            </swiper-slide>
            <swiper-slide>
              {/* <div className="swiper-slide"> */}
              <div className="row event-item">
                <div className="col-lg-6">
                  <img
                    src={require("../assets/img/communications1.jpeg")}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-lg-6 pt-4 pt-lg-0 content">
                  <h3>Communications</h3>
                  <div className="price">
                    <p>
                      <span></span>
                    </p>
                  </div>
                  <p className="fst-italic mb-2">
                    We Undertake artistic ventures, marketing, promotions,
                    advertisements, public relations, communications, new age
                    technologies.
                  </p>
                  <p>
                    Our group of experienced professionals with over twenty
                    years experience working with major companies, produce
                    result-oriented advertising, high impact marketing, websites
                    to give our clientele the added exposure and positioning.
                  </p>
                  <br />
                  <p>
                    We brand companies, products and partner with you on major
                    campaigns, events, and networking opportunities. We help
                    your company achieve the qualified lead generation and sales
                    results you need by providing a full range of targeted
                    integrated marketing communication services, techniques,
                    strategies and tactics.
                  </p>
                </div>
              </div>
              {/* </div><!-- End testimonial item --> */}
            </swiper-slide>
          </swiper-container>
          {/* </div> */}
          <div className="swiper-pagination"></div>
        </div>
      </div>
    </section>
  );
}

export default Services;
