// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.bundle';
// import '../assets/css/style.css';
// import '../assets/js/main';
import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { List, X, ChevronDown } from "react-bootstrap-icons";

function Header() {
  const ref = useRef(null);
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    const header = document.querySelector("#header");

    if (header) {
      const headerScrolled = () => {
        header.classList.toggle("header-scrolled", window.scrollY > 100);
      };

      window.addEventListener("load", headerScrolled);
      document.addEventListener("scroll", headerScrolled);
    }

    const backtotop = document.querySelector(".back-to-top");
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add("active");
        } else {
          backtotop.classList.remove("active");
        }
      };
      window.addEventListener("load", toggleBacktotop);
      document.addEventListener("scroll", toggleBacktotop);
    }

    const handleClick = (event) => {
      setIsActive(!isActive);
      document.querySelector("#navbar").classList.toggle("navbar-mobile");
    };

    const element = ref.current;

    element.addEventListener("click", handleClick);

    document.querySelector(".navbar .dropdown > a").addEventListener(
      "click",
      function (e) {
        if (
          document.querySelector("#navbar").classList.contains("navbar-mobile")
        ) {
          e.preventDefault();
          this.nextElementSibling.classList.toggle("dropdown-active");
        }
      },
      true
    );

    return () => {
      element.removeEventListener("click", handleClick);
    };
  }, []);
  return (
    <>
      <header id="header" className="fixed-top d-flex align-items-cente">
        <div className="container-fluid container-xl d-flex align-items-center justify-content-lg-between">
          {/* <!--h1 className="logo me-auto me-lg-0"><a href="index.html">2020 Group LLC</a></h1--> */}
          {/* <!-- Uncomment below if you prefer to use an image logo --> */}
          <Link
            to="/"
            className="logo me-auto me-lg-0"
            onClick={() => {
              setIsActive(!isActive);
              document
                .querySelector("#navbar")
                .classList.toggle("navbar-mobile");
              console.log(isActive);
            }}
          >
            <img
              src={require("../assets/img/2020group_logo_white.png")}
              alt=""
              className="img-fluid"
            />
          </Link>

          <nav id="navbar" className="navbar order-last order-lg-0">
            <ul>
              <li>
                <HashLink
                  className="nav-link scrollto active"
                  to="/#hero"
                  onClick={() => {
                    setIsActive(!isActive);
                    document
                      .querySelector("#navbar")
                      .classList.toggle("navbar-mobile");
                    console.log(isActive);
                  }}
                >
                  Home
                </HashLink>
              </li>
              <li>
                <HashLink
                  className="nav-link scrollto"
                  to="/#about"
                  onClick={() => {
                    setIsActive(!isActive);
                    document
                      .querySelector("#navbar")
                      .classList.toggle("navbar-mobile");
                    console.log(isActive);
                  }}
                >
                  What We Do
                </HashLink>
              </li>
              <li>
                <HashLink
                  className="nav-link scrollto"
                  to="/#why-us"
                  onClick={() => {
                    setIsActive(!isActive);
                    document
                      .querySelector("#navbar")
                      .classList.toggle("navbar-mobile");
                    console.log(isActive);
                  }}
                >
                  Why Us
                </HashLink>
              </li>
              <li>
                <HashLink
                  className="nav-link scrollto"
                  to="/#overview"
                  onClick={() => {
                    setIsActive(!isActive);
                    document
                      .querySelector("#navbar")
                      .classList.toggle("navbar-mobile");
                    console.log(isActive);
                  }}
                >
                  Overview
                </HashLink>
              </li>
              <li>
                <HashLink
                  className="nav-link scrollto"
                  to="/#services"
                  onClick={() => {
                    setIsActive(!isActive);
                    document
                      .querySelector("#navbar")
                      .classList.toggle("navbar-mobile");
                    console.log(isActive);
                  }}
                >
                  Our Services
                </HashLink>
              </li>
              <li className="dropdown" id="dropdown">
                <a href="#" onClick={() => {
                        document.getElementById('dropdown').nextElementSibling.classList.toggle('dropdown-active')
                      }}>
                  <span>Services</span>{" "}
                  <i className="bi bi-chevron-down">
                    <ChevronDown />
                  </i>
                </a>
                <ul>
                  <li>
                    <Link
                      to="/international"
                      onClick={() => {
                        setIsActive(!isActive);
                        document
                          .querySelector("#navbar")
                          .classList.toggle("navbar-mobile");
                        console.log(isActive);
                      }}
                    >
                      International
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/consults"
                      onClick={() => {
                        setIsActive(!isActive);
                        document
                          .querySelector("#navbar")
                          .classList.toggle("navbar-mobile");
                        console.log(isActive);
                      }}
                    >
                      Consults
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/investment"
                      onClick={() => {
                        setIsActive(!isActive);
                        document
                          .querySelector("#navbar")
                          .classList.toggle("navbar-mobile");
                        console.log(isActive);
                      }}
                    >
                      Investment
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/communication"
                      onClick={() => {
                        setIsActive(!isActive);
                        document
                          .querySelector("#navbar")
                          .classList.toggle("navbar-mobile");
                        console.log(isActive);
                      }}
                    >
                      Communication
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <HashLink
                  className="nav-link scrollto"
                  to="/#contact"
                  onClick={() => {
                    setIsActive(!isActive);
                    document
                      .querySelector("#navbar")
                      .classList.toggle("navbar-mobile");
                    console.log(isActive);
                  }}
                >
                  Contact
                </HashLink>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle">
              {isActive ? (
                <X
                  className="mobile-nav-toggle"
                  size={30}
                  id="navbar-toggle"
                  ref={ref}
                  onClick={() => {
                    setIsActive(!isActive);
                    document
                      .querySelector("#navbar")
                      .classList.toggle("navbar-mobile");
                    console.log(isActive);
                  }}
                />
              ) : (
                <List
                  className="mobile-nav-toggle"
                  size={30}
                  id="navbar-toggle"
                  ref={ref}
                  onClick={() => {
                    setIsActive(!isActive);
                    document
                      .querySelector("#navbar")
                      .classList.toggle("navbar-mobile");
                    console.log(isActive);
                  }}
                />
              )}
            </i>
          </nav>
          <HashLink
            to="/#contact"
            className="book-a-table-btn scrollto d-none d-lg-flex"
            onClick={() => {
              setIsActive(!isActive);
              document
                .querySelector("#navbar")
                .classList.toggle("navbar-mobile");
              console.log(isActive);
            }}
          >
            Request a Call
          </HashLink>
        </div>
      </header>
    </>
  );
}

export default Header;
